import Header from "../components/Header";
import Home from "./Home";
import {Router, Switch} from "react-router-dom/";
import history from "../util/history";
import {Translation} from "react-i18next";
import {Route} from "react-router-dom";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import Footer from "../components/Footer";
import Statues from "./Statues";
import AboutIslam from "./AboutIslam";
import ArabicEducation from "./ArabicEducation";
import IslamicEducation from "./IslamicEducation";
import Quran from "./Quran";
import Education from "./Education";
import LegalNotice from "./LegalNotice";
import {createContext, useState} from "react";
import WorkShops from "./WorkShops";
import AssociationEvents from "./AssociationEvents";
import Donation from "./Donation";
import Activities from "./Activities";
import Organigram from "./Organigram";

export const LanguageContext = createContext();

const App = () => {
    const [lang, setLang] = useState("de")
    const setLanguage = (language) => {
        setLang(language)
        console.log(language)
    }


    return (
        <>

            <LanguageContext.Provider value={lang}>
                <Translation>
                    {t =>
                        <Router history={history}>
                            <Header t={t} setLanguage={setLanguage}/>
                            <Switch>
                                <Route exact path={"/"} component={Home}/>

                                {/*Abouts Section*/}
                                <Route exact path={"/about-islam"} component={AboutIslam}/>
                                <Route exact path={"/organigram"} component={Organigram}/>
                                <Route exact path={"/statues"} component={Statues}/>
                                <Route exact path={"/about"} language={lang} component={AboutUs}/>
                                <Route exact path={"/contact"} component={ContactUs}/>

                                {/*Abouts Section*/}

                                {/*Education Section*/}
                                <Route exact path={"/education"} component={Education}/>
                                <Route exact path={"/arabic-education"} component={ArabicEducation}/>
                                <Route exact path={"/islamic-education"} component={IslamicEducation}/>
                                <Route exact path={"/quran"} component={Quran}/>
                                {/*Education Section*/}

                                {/*Activities Section*/}
                                <Route exact path={"/activities"} component={Activities}/>
                                <Route exact path={"/workshops"} component={WorkShops}/>
                                <Route exact path={"/events"} component={AssociationEvents}/>
                                {/*Activities Section*/}

                                {/*Donation Section*/}
                                <Route exact path={"/donation"} component={Donation}/>
                                {/*Donation Section*/}

                                {/*Legals Section*/}
                                <Route exact path={"/legal"} component={LegalNotice}/>
                                <Route exact path={"/data-protection"} component={LegalNotice}/>
                                {/*Legals Section*/}

                            </Switch>
                            <Footer/>
                        </Router>
                    }
                </Translation>
            </LanguageContext.Provider>
        </>
    );
}

export default App;
