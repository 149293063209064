import {PRAYER_TIME} from "../actions/types";

export function vendor(state = {}, action) {
    switch (action.type) {
        case PRAYER_TIME:
            console.log("reducer: " + action.payload)
            return {
                info: action.payload,
            }
        default:
            return state;
    }
}
