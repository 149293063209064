import {Link} from "react-router-dom";
import {t} from "i18next";

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                <div className="footer-widget">
                                    <h4>{t("ContactUs")}</h4>
                                    <div className="about-foo">
                                        <ul>
                                            {/*<li><span className="ico"><i className="fa fa-phone"></i></span><strong>+1*/}
                                            {/*    800 123 4567</strong></li>*/}
                                            <li><span className="ico"><i className="fa fa-envelope"></i></span><span
                                                className="con">info@ig-roding.de</span></li>
                                            <li><span className="ico"><i className="fa fa-map-marker"></i></span><span
                                                className="con">Regensburger Str. 18 </span></li>
                                            <li><span className="ico"><i className=""></i></span><span
                                                className="con" style={{marginLeft: 25}}>93426 Roding</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="footer-widget">
                                    <h4>{t("BankDetails")}</h4>
                                    <div className="about-foo">
                                        <ul>
                                            <li><span className="ico"></span><span
                                                className="con">Islamische Gemeinde Roding e.V.</span></li>
                                            <li><span className="ico"></span><span
                                                className="con">Sparkasse im Landkreis Cham
                                            </span></li>
                                            <li><span className="ftr"></span><span
                                                className="con">{t("IBAN")}: DE98 7425 1020 0052 5932 33</span></li>
                                            <li><span className="ico"></span><span
                                                className="con">{t("BIC")}: BYLADEM1CHM</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                                <div className="footer-widget">
                                    <h4>{t("UseFullLinks")}</h4>
                                    <div className="link-foo">
                                        <ul>
                                            <li><Link to="/about"><i className="fa fa-hand-o-right"></i>{t("AboutUs")}
                                            </Link>
                                            </li>
                                            <li><Link to="/donation"><i
                                                className="fa fa-hand-o-right"></i>{t("Donation")}
                                            </Link>
                                            </li>
                                            <li><Link to="/legal"><i
                                                className="fa fa-hand-o-right"></i>{t("LegalNotice")}
                                            </Link>
                                            </li>
                                            <li><Link to="/data-protection"><i
                                                className="fa fa-hand-o-right"></i>{t("DataProtection")}
                                            </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="foo-bot">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12 pd-0">
                                            <div className="logo-area">
                                                <Link to="/"><img style={{width: 60, height: 80}}
                                                                  src="/images/slider/igrwhite.png" alt=""/></Link>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12 pd-0" style={{marginTop: 20}}>
                                            <div className="copyright text-right">
                                                <p>© Copyright {new Date().getFullYear()} by <span>Islamisch Gemeinde Roding</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer
