export const HOME_INFO = "HOME_INFO";
export const CONTACT = "CONTACT_INFO";
export const COMMUNITY = "COMMUNITY";
export const INFORMATION = "INFORMATION";
export const PRAYER_TIME = "PRAYER_TIME";
export const PAGE = "PAGE";
export const HOME = "HOME";
export const ABOUT = "ABOUT";
export const ISLAM = "ISLAM";
export const EDUCATION = "EDUCATION";
export const ACTIVITIES = "ACTIVITIES";
export const DONATION = "DONATION";
export const RESET = "RESET";
export const ORGANIGRAM = "ORGANIGRAM";









