import {connect} from "react-redux";
import {getPagePostContent, resetPageContent} from "../actions";
import {useContext, useEffect} from "react";
import {LanguageContext} from "../pages/App";
import parse from 'html-react-parser';
import {PAGE} from "../actions/types";

const BlogTemplate = (props) => {


    const languageContext = useContext(LanguageContext)
    useEffect(() => {
        console.log("context use effect")
        loadContent()
    }, [languageContext])

    useEffect(() => {
        console.log("simple use effect")
        loadContent()
    }, [])
    const resetPage = () => {
        props.resetPageContent()
        window.onload = () => {
            console.log("Page Reloaded")
            localStorage.setItem("lang", "de")
            loadContent()
        }
    }
    const loadContent = () => {
        if (props.pageName) {
            resetPage()
            const savedLang = localStorage.getItem("lang")
            console.log("CallLoadContent " + savedLang)
            if (savedLang === "en") {
                props.getPagePostContent("english", props.pageName, PAGE)
            } else if (savedLang === "de") {
                console.log("German Lang Api Call")
                props.getPagePostContent("german", props.pageName, PAGE)
            } else {
                props.getPagePostContent("arabic", props.pageName, PAGE)
            }
        }

    }

    const renderBlogs = () => {
        if (props.blogs != null && props.blogs.posts != null && props.blogs.posts.length > 0) {
            return props.blogs.posts.map(blog => {
                console.log(blog.title)
                return (
                    <div key={blog._id} id={blog.tag}
                         style={{marginTop: 50, marginBottom: 10}}>
                        <div className="container">
                            {parse(blog.content)}
                        </div>
                    </div>
                )


            })
        }
    }

    return (
        <>
            {
                renderBlogs()
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        blogs: state.page.blogs
    }
}
export default connect(mapStateToProps, {getPagePostContent, resetPageContent})(BlogTemplate)
