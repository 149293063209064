import React, {useEffect} from "react";
import BlogTemplate from "../components/BlogTemplate";
import {DONATION} from "../actions/types";

const Donation = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <img src={"/images/banner/Donation 1a.png"} alt={""}
                 className="breadcumb-area-about bg-img jarallax af">
            </img>
            <BlogTemplate pageName={"donation"} pageType={DONATION}/>
        </>
    )
}

export default Donation
