import axios from "axios";

const agent = axios.create();

if (process.env.NODE_ENV === "production") {
  agent.defaults.baseURL = "https://igr-cms-0fdw.onrender.com/api";
} else {
  agent.defaults.baseURL = "http://localhost:3001/api";
}

agent.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

agent.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (error) => {
    return error.response;
  }
);

export default agent;
