import {t} from "i18next";
import React, {useEffect} from "react";

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <img src={"/images/banner/Contact 2.png"} alt={""}
                 className="breadcumb-area-about bg-img jarallax af">
            </img>
            <section className="contact-area pd-t80 pd-b100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="map-area">
                                <div id="map" className="map-full" style={{width: 100, height: 585}}>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.3034933005442!2d12.51228187716647!3d49.19480197137974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ffd3fe2e419dd%3A0x4330359a7189096f!2sRegensburger%20Str.%2018%2C%2093426%20Roding!5e0!3m2!1sen!2sde!4v1681981265608!5m2!1sen!2sde"
                                        width="1000" height="450" style={{border: 0}} allowFullScreen="" loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mr-b5">
                        <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12">
                            <div className="contact-info">
                                <span><i className="fa fa-map-marker"></i></span>
                                <p>Regensburger Str. 18<br/>
                                    93426 Roding</p>


                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12">
                            <div className="contact-info">
                                <span><i className="fa fa-phone"></i></span>
                                <p>+491749682517</p>
                                <p>+4917623232824</p>
                                <p></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12">
                            <div className="contact-info">
                                <span><i className="fa fa-envelope"></i></span>
                                <p>info@ig-roding.de</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="section-heading-two">
                                <h2>{t("GetTouch")}</h2>
                                <p>{t("ContactMessage")}</p>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-area">
                                <form action="https://formspree.io/f/xjvdjwkb"
                                      method="POST">
                                    <fieldset>
                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12 feld">
                                                <input type="text" name={"name"} placeholder={t("FullName")}/>
                                                <span><i className="fa fa-user"></i></span>
                                            </div>
                                            <div className="col-sm-4 col-xs-12 feld">
                                                <input type="email" name="email" placeholder={t("Email")}/>
                                                <span><i className="fa fa-envelope"></i></span>
                                            </div>
                                            <div className="col-sm-4 col-xs-12 feld">
                                                <input type="text" name={"subject"} placeholder={t("Subject")}/>
                                                <span><i className="fa fa-star"></i></span>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <div className="feld">
                                            <textarea name="message" placeholder={t("Message")}></textarea>
                                            <span className="msg"><i className="fa fa-pencil-square-o"></i></span>
                                        </div>
                                    </fieldset>
                                    <div className="btn-area text-center">
                                        <button type={"submit"} className="btn4"><span>{t("SendNow")}</span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs
