import {combineReducers} from "redux";

import {vendor} from "./vendor.reducer.js";
import {page} from "./page.reducer";

const rootReducer = combineReducers({
    vendor,
    page
})

export default rootReducer
