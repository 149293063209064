import {useEffect} from "react";
import {ISLAM} from "../actions/types";
import {Link} from "react-scroll";
import {t} from "i18next";
import BlogTemplate from "../components/BlogTemplate";

const AboutIslam = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <img src={"/images/banner/About Islam 3.png"} alt={""}
                 className="breadcumb-area-about bg-img jarallax af">
            </img>
            <section className="videos-area-about section">
                <div className={"figure-block"}>
                    <div className={"figure-circle-div"}>
                        <Link to={"allah"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/Icons-Islam-Allah.png"
                                     alt="image"/>
                            </div>
                            <p className={"figure-text"}>{t("Allah")}</p>
                        </Link>
                    </div>


                    <div className={"figure-circle-div"}>
                        <Link to={"muhammad"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/Icons-Islam-Muhammad.png"
                                     alt="image"/>
                            </div>
                            <p className={"figure-text"}>{t("Muhammad")}</p>
                        </Link>
                    </div>
                    <div className={"figure-circle-div"}>
                        <Link to={"islam"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/Icons-Islam-About%20Islam%202.png"
                                     alt="image"/>
                            </div>
                            <p className={"figure-text"}>{t("WhatIslam")}</p>
                        </Link>
                    </div>


                    <div className={"figure-circle-div"}>
                        <Link to={"pillarsofislam"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/Icons-Islam-Islamic%20Pillars.png"
                                     alt="image"/>
                            </div>
                        </Link>
                        <p className={"figure-text"}>{t("Pillars")}</p>
                    </div>
                </div>

                <BlogTemplate pageName={"about-islam"} pageType={ISLAM}/>

            </section>
        </>
    )
}
export default AboutIslam
