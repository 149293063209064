import {t} from "i18next";
import {Link} from "react-router-dom";
import {useEffect} from "react";

const Statues = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const renderDocument = () => {
        const savedLang = localStorage.getItem("lang")
        if (savedLang === "en") {
            return (
                <div className="w-50 floatleft pd-l15">
                    <a href={"/document/SatzungEn.pdf#toolbar=0"}><h4><span><i
                        className="fa fa-file"></i></span>Statutes</h4></a>
                    <p></p>
                </div>
            )
        } else {
            return (
                <div className="w-50 floatleft pd-r15">
                    <a href={"/document/ASatzungDe.pdf#toolbar=0"}><h4><span><i
                        className="fa fa-file"></i></span>Satzung</h4></a>
                    <p></p>
                </div>
            )
        }
    }

    return (
        <>
            <section className="breadcumb-area bg-img jarallax af">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="breadcumb">
                                <h2>{t("Statutes")}</h2>
                                <ul className="list-inline">
                                    <li><Link to={"/"}>{t("Home")}</Link></li>
                                    <li><i className="fa fa-angle-right"></i></li>
                                    <li><a>{t("Statutes")}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="videos-area-about section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="section-heading-two">
                                <h2>{t("Statutes")}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="video-content">
                                <p></p>
                                <div className="about">
                                    {renderDocument()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Statues
