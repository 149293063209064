import agent from "../api/agent";
import {COMMUNITY, CONTACT, EDUCATION, HOME, INFORMATION, PAGE, RESET} from "./types";


export const getPageContent = () => async (dispatch) => {

}
export const getPagePostContent = (selectedLanguage, pageName, pageType) => async (dispatch) => {
    const response = await agent.get(`publicPosts?language=${selectedLanguage}&pageName=${pageName}`)
    console.log("Response " + response.data)
    if (response.status === 200) {
        dispatch({
            type: pageType,
            payload: response.data,
        })
    }
}

export const getPageContentOld = (selectedLanguage, pageName) => async (dispatch) => {
    const response = await agent.get(`publicPosts?language=${selectedLanguage}&pageName=${pageName}`)
    console.log("Response " + response.data)
    if (response.status === 200) {
        dispatch({
            type: PAGE,
            payload: response.data,
        })
    }
}

export const resetPageContent = () => async (dispatch) => {
    dispatch({
        type: RESET,
        payload: {},
    })
}

export const getHomePage = (selectedLanguage) => async (dispatch) => {
    const response = await agent.get(`home?selectedLanguage=${selectedLanguage}`)
    if (response.status === 200) {
        dispatch({
            type: HOME,
            payload: response.data,
        })
    }
}

export const getAboutPage = (selectedLanguage) => async (dispatch) => {
    const response = await agent.get(`about?selectedLanguage=${selectedLanguage}`)
    if (response.status === 200) {
        dispatch({
            type: HOME,
            payload: response.data,
        })
    }
}


export const getContactPage = (selectedLanguage) => async (dispatch) => {
    const response = await agent.get(`contact?selectedLanguage=${selectedLanguage}`)
    if (response.status === 200) {
        dispatch({
            type: CONTACT,
            payload: response.data,
        })
    }
}


export const getEducationPage = (selectedLanguage) => async (dispatch) => {
    const response = await agent.get(`education?selectedLanguage=${selectedLanguage}`)
    if (response.status === 200) {
        dispatch({
            type: COMMUNITY,
            payload: response.data,
        })
    }
}

export const getEventsPage = (selectedLanguage) => async (dispatch) => {
    const response = await agent.get(`events?selectedLanguage=${selectedLanguage}`)
    if (response.status === 200) {
        dispatch({
            type: EDUCATION,
            payload: response.data,
        })
    }
}

export const getWorkshopsPage = (selectedLanguage) => async (dispatch) => {
    const response = await agent.get(`workshops?selectedLanguage=${selectedLanguage}`)
    if (response.status === 200) {
        dispatch({
            type: INFORMATION,
            payload: response.data,
        })
    }
}


