import {t} from "i18next";
import {connect} from "react-redux";
import {getPagePostContent} from "../actions";
import {useEffect} from "react";

const Slider = (props) => {
    useEffect(() => {
        props.getPagePostContent()
    }, []);
    return (
        <>
            <section className="slider-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 msj_slider_padding">
                            <div id="carouselExampleIndicators" className="slide carousel" data-ride="carousel">
                                <div className="carousel-inner" role="listbox">
                                    <div className="carousel-item active">
                                        <img style={{maxWidth: '100%', height: "auto"}} src={t("WelcomeImage1")}
                                             alt=""/>
                                    </div>
                                    <div className="carousel-item">
                                        <img style={{maxWidth: '100%', height: "auto"}} src={t("WelcomeImage2")}
                                             alt=""/>
                                    </div>
                                    {/*<div className="carousel-item">*/}
                                    {/*    <img style={{maxWidth: '250%', height: "auto"}} src={t("WelcomeImage3")}*/}
                                    {/*         alt=""/>*/}
                                    {/*</div>*/}
                                    <div className="carousel-item">
                                        <img style={{maxWidth: '100%', height: "auto"}} src={t("WelcomeImage4")}
                                             alt=""/>
                                    </div>
                                </div>
                                <a className="carousel-control-prev carousel-control" href="#carouselExampleIndicators"
                                   role="button" data-slide="prev"><i className="flaticon-back"></i></a>
                                <a className="carousel-control-next carousel-control" href="#carouselExampleIndicators"
                                   role="button" data-slide="next"><i className="flaticon-next"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        slides: state.page.slides
    }
}

export default connect(mapStateToProps, {getPagePostContent})(Slider)
