import {t} from "i18next";
import {Link} from "react-router-dom";

const IslamicEducation = () => {
    return (
        <>
            <section className="breadcumb-area bg-img jarallax af">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="breadcumb">
                                <h2>{t("AboutUs")}</h2>
                                <ul className="list-inline">
                                    <li><Link to={"/"}>{t("Home")}</Link></li>
                                    <li><i className="fa fa-angle-right"></i></li>
                                    <li><a>{t("AboutUs")}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="videos-area-about section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="section-heading-two">
                                <h2>{t("WhoWeAre")}</h2>
                                <p>To be added</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="video-content">
                                <h2>{t("AboutUs")}</h2>
                                <p></p>
                                <div className="about">
                                    <div className="w-50 floatleft pd-r15">
                                        <h4><span><i className="fa fa-eye"></i></span>{t("OurVision")}</h4>
                                        <p></p>
                                    </div>
                                    <div className="w-50 floatleft pd-l15">
                                        <h4><span><i className="fa fa-flag"></i></span>{t("OurAim")}</h4>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default IslamicEducation
