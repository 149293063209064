import {ABOUT, ACTIVITIES, EDUCATION, HOME, ISLAM, PAGE, RESET} from "../actions/types";

export function page(state = {}, action) {
    switch (action.type) {
        case PAGE:
            console.log("reducer: " + action.payload)
            return {
                blogs: action.payload
            }
        case HOME:
            console.log("reducer: " + action.payload)
            return {
                slides: action.payload
            }
        case ABOUT:
            console.log("reducer: " + action.payload)
            return {
                about: action.payload
            }
        case ISLAM:
            console.log("reducer: " + action.payload)
            return {
                islam: action.payload
            }
        case EDUCATION:
            console.log("reducer: " + action.payload)
            return {
                education: action.payload
            }
        case ACTIVITIES:
            console.log("reducer: " + action.payload)
            return {
                activities: action.payload
            }
        case RESET:
            console.log("reducer: " + action.payload)
            return {
                reset: action.payload
            }
        default:
            return state;
    }
}
