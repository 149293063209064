import {t} from "i18next";
import BlogTemplate from "../components/BlogTemplate";
import {useEffect} from "react";
import {Link} from "react-scroll";
import {ABOUT} from "../actions/types";


const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <img src={"/images/banner/Association 1.png"} alt={""}
                 className="breadcumb-area-about bg-img jarallax af">
            </img>

            <section className="videos-area-about section">
                <div className={"figure-block"}>
                    <div className={"figure-circle-div"}>
                        <Link to={"aboutus"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/igr-icons-white_6-igr.png"
                                     alt="image"/>
                            </div>
                            <p className={"figure-text"}>{t("AboutUs")}</p>
                        </Link>
                    </div>


                    <div className={"figure-circle-div"}>
                        <Link to={"organigram"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/organogram.png"
                                     alt="image"/>
                            </div>
                            <p className={"figure-text"}>{t("Organigram")}</p>
                        </Link>
                    </div>
                    {/*<div className={"figure-circle-div"}>*/}
                    {/*    <Link to={"ourgoal"} offset={-400}>*/}
                    {/*        <div className={"figure-circle"}>*/}
                    {/*            <img src="/images/design/igr-icons_40.svg"*/}
                    {/*                 alt="image"/>*/}
                    {/*        </div>*/}
                    {/*        <p className={"figure-text"}>{t("OurGoal")}</p>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}


                    <div className={"figure-circle-div"}>
                        <Link to={"membership"} offset={-400}>
                            {/*<a href={"/document/MembershipDeAr.pdf"}>*/}

                            {/*</a>*/}
                            {/*<a href={"/document/MembershipDeEn.pdf"}>*/}

                            {/*</a>*/}
                            <div className={"figure-circle"}>
                                <img src="/images/design/igr-icons-white_22.png"
                                     alt="image"/>
                            </div>
                        </Link>
                        <p className={"figure-text"}>{t("Membership")}</p>
                    </div>
                    <div className={"figure-circle-div"}>
                        <Link to={"donation"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/igr-icons-white_14.png"
                                     alt="image"/>
                            </div>
                            <p className={"figure-text"}>{t("Donation")}</p>
                        </Link>
                    </div>

                    <div className={"figure-circle-div"}>
                        <Link to={"statutes"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/igr-icons-white_13.png"
                                     alt="image"/>
                            </div>
                            <p className={"figure-text"}>{t("Statutes")}</p>
                        </Link>
                    </div>
                    {/*<div className={"figure-circle-div"}>*/}
                    {/*    {renderDocument()}*/}
                    {/*</div>*/}
                </div>

                <BlogTemplate pageName={"about"} pageType={ABOUT}/>

            </section>

        </>
    )
}
export default AboutUs
