import {Link} from "react-router-dom";
import IGR_LOGO from "../assets/igrm_logo.png"
import {useEffect, useState} from "react";
import ii8n from "../util/LanguageConfig";
import {connect} from "react-redux";
import {fetchPrayerTimes} from "../actions/vendor.actions";
import dayjs from "dayjs";


const Header = (props) => {
    const [lang, setLang] = useState("de")
    const [navMode, setNavMode] = useState("none")
    const [subNavMode1, setSubNavMode1] = useState("none")
    const [subNavMode2, setSubNavMode2] = useState("none")
    const t = props.t
    // 16-4-2023?latitude=49.1941&longitude=12.5223&method=2
    useEffect(() => {
        const date = new Date().toLocaleDateString().replace(/[^apm\d]+/gi, '-')
        let formattedDate = dayjs(date, "D-MM-YYYY")
        console.log(formattedDate)
        props.fetchPrayerTimes(formattedDate, 4, 49.1941, 12.5223)
    }, [])
    useEffect(() => {
    }, [lang, navMode])

    const renderPrayerTimes = () => {
        console.log(props.prayerInfo)
        if (props.prayerInfo) {
            return (
                <>

                    <li>
                        <span className="count">{t("Fajr")}</span><span
                        className="count">{props.prayerInfo.timings.Fajr}</span>
                    </li>
                    <li><span className="count">{t("Dhuhr")}</span><span
                        className="count">{props.prayerInfo.timings.Dhuhr}</span></li>
                    <li><span className="count">{t("Asr")}</span><span
                        className="count">{props.prayerInfo.timings.Asr}</span></li>
                    <li><span className="count">{t("Maghrib")}</span><span
                        className="count">{props.prayerInfo.timings.Maghrib}</span>
                    </li>
                    <li><span className="count">{t("Isha")}</span><span
                        className="count">{props.prayerInfo.timings.Isha}</span></li>
                </>
            )
        }
    }


    const langChange = e => {
        localStorage.setItem("lang", e.target.value)
        const savedLang = localStorage.getItem("lang")
        setLang(savedLang)
        props.setLanguage(savedLang)
        console.log("Selected Language : " + savedLang)
        ii8n.changeLanguage(savedLang)

    }
    return (
        <header>
            <div className="topbar">
                <div>
                    <div>
                        <div className="hitem header-event">
                            <ul className="list-inline count-list">
                                {renderPrayerTimes()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-header hidden-sm-down" id="sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1 col-md-3 col-sm-12 col-xs-12">
                            <div className="logo-area">
                                <Link to="/"><img src={IGR_LOGO} style={{width: "auto", height: 80}} alt=""/></Link>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-9 col-sm-12 col-xs-12">
                            <div className="menu-area" style={{marginTop: 5}}>
                                <nav>
                                    <ul className="list-inline">
                                        <li><Link to="/">{t("Home")}</Link></li>
                                        <li className="drop-menu">
                                            <Link to={"/about"}>{t("Association")}</Link>
                                            {/*<ul className="down-menu">*/}
                                            {/*    <li><Link to="/about">{t("AboutUs")}</Link></li>*/}
                                            {/*    <li><Link to={"/statues"}>{t("Statutes")}</Link></li>*/}
                                            {/*</ul>*/}
                                        </li>
                                        <li><Link to="/about-islam">{t("AboutIslam")}</Link></li>
                                        <li><Link to="/education">{t("Education")}</Link></li>
                                        <li><Link to="/activities">{t("Activities")}</Link></li>

                                        <li><Link to="/contact">{t("ContactUs")}</Link></li>
                                        <li className="menu-btn">
                                            <ul>
                                                <li><Link to={"/donation"}
                                                          className="btn1"><span>{t("Donate")}</span></Link></li>
                                                <li>
                                                    <select className={"language-select"} name={"lang"} value={lang}
                                                            onChange={langChange}>
                                                        <option name={"de"} value={"de"}>German</option>
                                                        <option value={"en"}>English</option>
                                                        <option value={"ar"}>Arabic</option>
                                                    </select>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-menu-area">
                <div className="container mean-container">
                    <div className="mean-bar">
                        <a onClick={() => setNavMode(navMode === "none" ? "block" : "none")}
                           className="meanmenu-reveal"><span></span><span></span><span></span></a>
                        <nav className="mean-nav">
                            <ul className="list-inline" style={{display: navMode}}>
                                <li onClick={() => setNavMode(navMode === "none" ? "block" : "none")}><Link
                                    to="/">{t("Home")}</Link></li>
                                <li onClick={() => setNavMode(navMode === "none" ? "block" : "none")}><Link
                                    to="/about">{t("Association")}</Link></li>
                                {/*<li className="drop-menu">*/}
                                {/*    <a onClick={() => setSubNavMode1(subNavMode1 === "none" ? "block" : "none")}>{t("Association")}</a>*/}
                                {/*    <ul className="down-menu" style={{display: subNavMode1}}>*/}
                                {/*        <li onClick={() => setNavMode(navMode === "none" ? "block" : "none")}><Link*/}
                                {/*            to="/about">{t("AboutUs")}</Link></li>*/}
                                {/*        <li onClick={() => setNavMode(navMode === "none" ? "block" : "none")}><Link*/}
                                {/*            to={"/statues"}>{t("Statutes")}</Link></li>*/}
                                {/*    </ul>*/}
                                {/*    <a className="mean-expand"*/}
                                {/*       onClick={() => setSubNavMode1(subNavMode1 === "none" ? "block" : "none")}*/}
                                {/*       style={{fontSize: 18}}>+</a></li>*/}

                                <li><Link to="/about-islam">{t("AboutIslam")}</Link></li>
                                <li><Link to="/education">{t("Education")}</Link></li>
                                <li><Link to="/activities">{t("Activities")}</Link></li>
                                <li><Link to="/donation">{t("Donation")}</Link></li>
                                <li className="mean-last"><Link to={"/contact"}>{t("ContactUs")}</Link></li>
                                <li>
                                    <select className={"language-select"} name={"lang"} value={lang}
                                            onChange={langChange}>
                                        <option name={"de"} value={"de"}>German</option>
                                        <option value={"en"}>English</option>
                                        <option value={"ar"}>Arabic</option>
                                    </select>
                                </li>
                            </ul>
                        </nav>

                    </div>

                </div>
            </div>
        </header>

    )
}

const mapStateToProps = (state) => {

    return {
        prayerInfo: state.vendor.info
    }
}


export default connect(mapStateToProps, {fetchPrayerTimes})(Header)
