import Slider from "../components/Slider";
import {useEffect} from "react";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Slider/>
            {/*<JoinBanner/>*/}
            {/*<AnnouncementsBanner/>*/}
            {/*<IslamBanner/>*/}
            {/*<PrayerBanner/>*/}
            {/*<PillarsBanner/>*/}
        </>
    )
}

export default Home;
