import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import {Provider} from "react-redux";
import {store} from "./reducers/store";

// import "../src/styles/style.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);
