import {t} from "i18next";
import {Link} from "react-scroll";
import BlogBanner from "../components/BlogTemplate";
import {EDUCATION} from "../actions/types";

const Education = () => {
    return (
        <>
            <img src={"/images/banner/Education 3.png"} alt={""}
                 className="breadcumb-area-about bg-img jarallax af">
            </img>

            <section className="videos-area-about section">

                <div className={"figure-block"}>
                    <div className={"figure-circle-div"}>
                        <Link to={"arabic"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/Icons-Education-Arabic.png"
                                     alt="image"/>
                            </div>
                            <p className={"figure-text"}>{t("ArabicEducation")}</p>
                        </Link>
                    </div>


                    <div className={"figure-circle-div"}>
                        <Link to={"islam"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/Icons-Education-Islamic%20Religion.png"
                                     alt="image"/>
                            </div>
                            <p className={"figure-text"}>{t("IslamicEducation")}</p>
                        </Link>
                    </div>
                    <div className={"figure-circle-div"}>
                        <Link to={"quran"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/Icons-Education-Quran.png"
                                     alt="image"/>
                            </div>
                            <p className={"figure-text"}>{t("Quran")}</p>
                        </Link>
                    </div>


                    <div className={"figure-circle-div"}>
                        <Link to={"schedule"} offset={-400}>
                            {/*<a href={"/document/MembershipDeAr.pdf"}>*/}

                            {/*</a>*/}
                            {/*<a href={"/document/MembershipDeEn.pdf"}>*/}

                            {/*</a>*/}
                            <div className={"figure-circle"}>
                                <img src="/images/design/Icons-Education-Schedule.png"
                                     alt="image"/>
                            </div>
                        </Link>
                        <p className={"figure-text"}>{t("Schedule")}</p>
                    </div>
                    <div className={"figure-circle-div"}>
                        <Link to={"fees"} offset={-400}>
                            <div className={"figure-circle"}>
                                <img src="/images/design/Icons-Education-Fees.png"
                                     alt="image"/>
                            </div>
                            <p className={"figure-text"}>{t("Fees")}</p>
                        </Link>
                    </div>


                </div>

                <BlogBanner pageName={"education"} pageType={EDUCATION}/>
            </section>
        </>
    )
}

export default Education
