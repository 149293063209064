import React from "react";
import BlogTemplate from "../components/BlogTemplate";
import {ACTIVITIES} from "../actions/types";


const Activities = () => {
    return (
        <>
            <img src={"/images/banner/Activities 1.png"} alt={""}
                 className="breadcumb-area-about bg-img jarallax af">
            </img>

            <section className="videos-area-about section">
                {/*<div className="container">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}
                {/*            <div className="section-heading-two">*/}
                {/*                <h2>{t("Activities")}</h2>*/}
                {/*                <p>To be added</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}
                {/*            <div className="video-content">*/}
                {/*                <p></p>*/}
                {/*                <div className="about">*/}
                {/*                    <div className="w-50 floatleft pd-r15">*/}
                {/*                        <h4><span><i className="fa fa-eye"></i></span>{t("Events")}</h4>*/}
                {/*                        <p></p>*/}
                {/*                    </div>*/}
                {/*                    <div className="w-50 floatleft pd-l15">*/}
                {/*                        <h4><span><i className="fa fa-flag"></i></span>{t("Workshops")}</h4>*/}
                {/*                        <p></p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <BlogTemplate pageName={"activities"} pageType={ACTIVITIES}/>

            </section>
        </>
    )
}

export default Activities
