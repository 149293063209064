import BlogBanner from "../components/BlogTemplate";
import {ORGANIGRAM} from "../actions/types";

const Organigram = () => {

    return (
        <BlogBanner pageName={"organigram"} pageType={ORGANIGRAM}/>
    )
}

export default Organigram
