import agent from "../api/agent";
import {PRAYER_TIME} from "./types";

// https://api.aladhan.com/v1/calendar/2023/4?latitude=49.1941&longitude=12.5223&method=2
// https://api.aladhan.com/v1/timings/16-4-2023?latitude=49.1941&longitude=12.5223&method=2
export const fetchPrayerTimes = (date, methodId, latitude, longitude) => async (dispatch) => {
    const response = await agent.get(`https://api.aladhan.com/v1/timings/${date}?latitude=${latitude}&longitude=${longitude}&method=${methodId}`)
    if (response.data.code === 200) {
        dispatch({
            type: PRAYER_TIME,
            payload: response.data.data,
        })
    }
}


